<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1136.05 296.07">
    <defs></defs>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_7" data-name="Capa 7">
        <path
          class="cls-1"
          d="M0,245.48C1.91,235,3.64,224.24,5.9,213.55c3.66-17.3,8-34.45,11.34-51.81,1.29-6.8.63-14,.66-21,0-14.85-.74-29.74.08-44.54,1-17.56,2.54-35.14,6.75-52.31,1.21-4.92,3.46-9.59,5.11-14.42Q33.5,18.78,37,8c.6-1.86,1-3.11,3.56-3.63,10.24-2.07,18.86,2,27.33,6.61C70.55,12.47,73,14,76.14,13.25a3.06,3.06,0,0,1,1.53,0c8,2.59,16.15,5.35,22.06,11.72,3,3.24,5.67,6,10.53,5.54,1.4-.14,3,.6,4.36,1.17,6.78,2.8,13.71,5.34,20.24,8.66,4.71,2.39,9,5.69,13.25,8.9,4.78,3.62,10.06,6.81,12.93,12.4,1,2,1.66,4.29,2.51,6.42a10,10,0,0,0,1.29,2.73c6,7.26,8.86,15.75,9.64,25,.26,3.1-.2,6.27-.06,9.39.11,2.2.71,4.38,1,6.58s1.12,4.28.78,6.27c-1.2,7.1-.27,14,1.42,20.83.36,1.47.36,3,.63,4.54.23,1.28.29,2.76,1,3.76,4.22,6.11,2.47,12-.21,18a63.4,63.4,0,0,0-3.22,9.42c-2,7.6-3.69,15.3-5.87,22.86-1.09,3.77-3.05,7.28-4.52,11-.75,1.89-1.31,3.85-1.93,5.79-1.46,4.57-2.63,9.26-4.46,13.68a16.39,16.39,0,0,1-4.65,5.93c-8.85,7.45-17.93,14.64-26.79,22.09-2.06,1.73-3.53,4.14-5.34,6.19-.57.63-1.3,1.44-2,1.54-7.93,1-14,5.81-20.73,9.51a58.51,58.51,0,0,1-10.14,4c-4.73,1.58-9.46,3.39-14.34,4.26-6.12,1.09-12.39,1.33-18.59,1.92a4.12,4.12,0,0,1-1.53-.07c-8.2-2.39-16.4-4.77-24.57-7.24-4.77-1.44-9.42-3.31-14.24-4.48-6.58-1.59-11.85-4.36-13.29-11.65C1.9,255.22,1,250.62,0,245.48ZM118.16,104l-.82.09c1.32-8.79-2.44-15.63-7.84-21.91-1.6-1.85-2.88-4-4.41-5.9-5.1-6.42-10.1-13-17.42-17.09-1.58-.88-4.06-1.85-5.29-1.2S80.89,61.2,80.46,63c-.5,2.05-.47,4.25-1.1,6.25-2.53,8.07-5.28,16.07-7.82,24.13a7.21,7.21,0,0,0-.11,4.21c1.25,3.59.77,7,0,10.58a62,62,0,0,0-.85,7.6c-.64,7.91-.5,16-2.07,23.69-1.59,7.87-1.37,15.63-1.59,23.49-.1,3.53-1.75,7-2.65,10.51-.37,1.44-1.11,3-.87,4.35,1.53,8.46-.91,16.15-4,23.82a21.62,21.62,0,0,0-1.24,6.16c-.36,4.07-.28,8.18-.74,12.24-.76,6.7-2.05,13.36-2.68,20.07-.55,5.93,1.85,7.43,7.4,5.18a4.87,4.87,0,0,0,1.1-.53c6.4-4.93,13.35-9.28,19-14.92,12.19-12.05,21-26.59,28.38-42,3-6.27,3.9-12.89,4-19.65.25-11.55,0-23.1.39-34.64.18-5.91,1.2-11.8,1.82-17.7C117.37,111.87,117.76,107.91,118.16,104Z"
        />
        <path
          class="cls-1"
          d="M267.33,122.86q19.4-1.86,38.78-3.69c6-.56,12-1.48,18-1.31,3.13.1,6.35,1.92,9.27,3.47,6.72,3.58,12.55,8,16.68,14.85s3.05,13.17-.87,19.21c-1.68,2.59-4.68,4.36-7.21,6.34-2.09,1.62-4.51,2.83-6.54,4.52a26.3,26.3,0,0,1-16.91,6.26,51.34,51.34,0,0,0-10,1.16c-13.6,3-27.43,3.81-41.29,4.4-6.24.26-8.39,3-8.3,9.17.08,4.86-.73,9.73-1.15,14.6a7.22,7.22,0,0,0-.22,1.81c1.33,7.3-1.25,14.22-2.34,21.22-.94,6.1,3.17,12.22,10.94,11a71.72,71.72,0,0,1,28,1c6.9,1.66,14.17,1.8,21.3,2.42a5.31,5.31,0,0,1,4,2.09,17.66,17.66,0,0,0,6.34,5c6.48,2.52,11.05,7.25,15.66,12.06a21.67,21.67,0,0,0,3.2,2.86c5.51,3.83,5.42,9.95-.27,13.63a6.88,6.88,0,0,1-2.5,1.13c-4.61.77-9.24,2-13.88,2-12,.09-23.95-.29-35.93-.61-2.92-.08-5.83-.9-8.75-1a132.4,132.4,0,0,0-16.19.24c-4,.41-7.78,2.19-11.75,2.87-2.65.45-5.46,0-8.2,0s-5.8-.59-8.37.2c-7.28,2.22-14.45.51-21.64,0-1.38-.09-2.95-1.45-3.93-2.64-3.4-4.11-6.75-8.26-9.73-12.67a11.49,11.49,0,0,1-1.8-6.29c.22-9.31.79-18.61,1.2-27.91.58-13,1-26,1.74-39,.63-10.9,1.34-21.81,2.55-32.66,1.51-13.62,3.23-27.23,5.5-40.74a185.09,185.09,0,0,0,2.53-30.54c0-6.53.72-13.06,1.16-19.6.22-3.27,5.7-9.09,9-9.57a36.46,36.46,0,0,1,12,.31c7.88,1.45,16,2.11,24-.41,3.34-1.05,7.06-1.1,10.62-1.19,16.89-.41,33.81-.11,50.64-1.19a102.38,102.38,0,0,1,31.56,2.37,17.06,17.06,0,0,1,7.2,4.21,33.08,33.08,0,0,1,5.34,7.46,7.11,7.11,0,0,1-.85,8.58c-2.21,2.66-4.6,5.14-8.61,3.52a2.12,2.12,0,0,0-.89-.22c-4.7.58-9.46.85-14.07,1.84-6.18,1.34-12.18,3.33-18.63,1.19a7.19,7.19,0,0,0-5,.65A24.66,24.66,0,0,1,306,87.85c-3.06-.15-6.18,1-9.29,1.47-4.1.59-8.22,1.07-12.34,1.58-.81.1-1.63.14-2.44.16-5.09.16-10.19.53-15.27.41s-6.67,1-6.68,6c0,4.16.45,8.32.61,12.48a18.82,18.82,0,0,1-.45,3.3C259.12,120.5,260.22,123.14,267.33,122.86Z"
        />
        <path
          class="cls-1"
          d="M679.92,253.94q1.26-26.38,2.53-52.77c.42-8.79.49-17.61,1.37-26.36,1.11-10.95,2.93-21.84,4.37-32.76,1.75-13.19,3.41-26.39,5.11-39.58.07-.61.07-1.23.09-1.84q.63-16.09,1.25-32.2c.14-3.67,5.43-9.64,9.09-10.16a36.46,36.46,0,0,1,12,.31c7.88,1.45,16,2.1,24-.41,3.43-1.07,7.26-1.1,10.92-1.19,16.78-.41,33.6-.1,50.33-1.19,10.76-.7,21.22-.24,31.55,2.39,2.78.7,5.45,2.67,7.62,4.66,2,1.82,3.32,4.4,4.78,6.74a7.34,7.34,0,0,1-.74,8.85c-2.21,2.67-4.62,5.13-8.62,3.5a1.92,1.92,0,0,0-.89-.22c-4.7.58-9.46.84-14.07,1.85-6.18,1.36-12.18,3.3-18.62,1.18a7.28,7.28,0,0,0-5,.66,24.23,24.23,0,0,1-12.44,2.46c-3.07-.16-6.19.92-9.3,1.37-4.21.6-8.43,1.13-12.64,1.67-1.32.17-2.64.33-4,.38-4.28.15-8.57.55-12.83.28-6.06-.37-7.65.73-7.61,6.84,0,3.86.47,7.71.66,11.56a13.92,13.92,0,0,1-.26,2.72A29.56,29.56,0,0,0,738,116c-.18,5.18,1.21,7,6.34,6.62,13.26-.92,26.49-2.24,39.73-3.37,6.31-.55,12.63-1.48,18.93-1.36,2.91.05,5.91,2,8.65,3.45,6.7,3.62,12.55,8,16.66,14.87s3,13.18-.89,19.21c-1.69,2.58-4.68,4.36-7.22,6.33-2.08,1.63-4.5,2.84-6.53,4.53a26.25,26.25,0,0,1-16.61,6.23,54.34,54.34,0,0,0-10.34,1.18c-13.61,3-27.44,3.8-41.3,4.39-6.23.26-8.38,3-8.26,9.19.09,4.77-.67,9.54-1.07,14.31-.05.61-.4,1.24-.3,1.8,1.32,7.4-1.22,14.43-2.35,21.53-1,6.1,3.28,12.14,10.65,11a72.44,72.44,0,0,1,29.21,1.21c6.46,1.65,13.37,1.68,20.09,2.18a5.62,5.62,0,0,1,4.51,2.37,15.73,15.73,0,0,0,6.17,4.76c6.49,2.49,11,7.25,15.66,12.06a21.11,21.11,0,0,0,3.2,2.85c5.49,3.81,5.39,10-.31,13.63a7,7,0,0,1-2.5,1.13c-4.62.76-9.25,2-13.88,2-12,.09-24-.29-35.94-.61-2.92-.08-5.82-.9-8.74-1a130.59,130.59,0,0,0-16.2.25c-4,.41-7.78,2.19-11.74,2.86-2.66.45-5.47,0-8.2,0s-5.8-.57-8.37.21c-7.28,2.23-14.46.53-21.64,0-1.46-.1-3.07-1.61-4.13-2.88-3.27-3.93-6.78-7.79-9.28-12.2-1.76-3.1-2-7-2.94-10.62Z"
        />
        <path
          class="cls-1"
          d="M988.65,88.48A124.05,124.05,0,0,1,985,101.1c-1.83,4.75-4.64,9.11-6.67,13.8-2.75,6.35-7.83,10.29-13.31,14-1.74,1.18-3,3.24-4.81,4.17-3.67,1.87-7.6,3.24-11.43,4.82a63.23,63.23,0,0,0-7.56,3.2c-3.2,1.86-2.82,3.46-.54,6.26s3.4,6.12,5.12,9.18a8.85,8.85,0,0,0,2.17,3c3.63,2.58,4.4,6.76,6,10.44,3.43,7.82,6.66,15.72,10,23.57a58.37,58.37,0,0,0,2.9,5.75c4.4,7.72,7.41,16,10.55,24.3,2,5.3,5.23,10.11,7.65,15.27a21.56,21.56,0,0,1,2.25,7.59c.67,11.07,5.93,20.73,9.51,30.82,3.84,10.82-6.38,19.61-15.58,18.81s-17.37-4.41-25.65-7.79c-5-2-6.34-5.62-5.75-11.12a8.41,8.41,0,0,0-.88-4.71c-7.39-13.49-13.36-27.69-23.42-39.8-5.9-7.1-9.35-16.23-14-24.4-3.42-6-6.91-12-10.52-17.94-.62-1-2-1.57-3-2.33-.75,1-1.83,1.94-2.21,3.08-3.63,11.18-7.15,22.39-10.69,33.6a2.77,2.77,0,0,0-.24,1.2c1.2,5.72-1.75,10.52-3.35,15.65-.54,1.73-.17,3.74-.33,5.61a49.36,49.36,0,0,1-.89,7.51c-1.11,4.45-2.65,8.79-3.85,13.22s-3,8.81-7,11.56a14.77,14.77,0,0,1-6.14,2.1c-6.8,1-11.18-2.25-13.94-8.33-1.69-3.71-3.85-7.21-5.64-10.88-1.53-3.14-.93-6,.48-9.32a89.47,89.47,0,0,0,4.55-15.78c4.45-20.47,8.2-41.11,13.24-61.43,3.63-14.65,9.23-28.79,13.34-43.33,4.48-15.83,8.17-31.87,12.34-47.77,2.46-9.4,5.2-18.71,7.78-28.07.59-2.16,1.07-4.36,1.51-6.55.61-3,1.67-3.66,4.62-2.94,2.34.57,4.78.73,7.13,1.26a15.21,15.21,0,0,1,4.26,1.6A15.41,15.41,0,0,0,924,52.09c13.85-2.56,26.95.16,39.38,6.06a98.33,98.33,0,0,1,15.84,9.91C983.85,71.51,988.37,81.17,988.65,88.48Zm-54-13.93-.07-.43h-3.07c-4,0-4.51.58-5.41,4.62-1.5,6.73-3,13.49-4.94,20.09-.95,3.17.36,4.41,2.82,4.74,6.78.93,13.94.84,19.79-2.37,8.4-4.61,13.45-12.21,12.66-22.47a4.6,4.6,0,0,0-1.92-3.14,17,17,0,0,0-12.67-2.21C939.47,73.91,937.05,74.17,934.65,74.55Z"
        />
        <path
          class="cls-1"
          d="M565.76,78.65c1,.11,1.92.3,2.89.33,3.35.08,6.71.06,10.06.17,5.1.17,10.24.11,15.29.72,4.71.57,9.54,8,9.41,12.93-.29,11-.32,22-.45,33a13,13,0,0,1,0,2.15c-1.62,8.34-.23,16.71-.31,25.06-.12,12.46,0,24.93,0,37.39,0,.49.09,1,.24,2.35,1.16-1.42,1.93-2.23,2.55-3.14,1.47-2.19,2.88-4.42,4.29-6.65a5.59,5.59,0,0,0,.78-1.59c.75-3.15,1.38-6.34,2.16-9.48,2.87-11.69,5.9-23.33,8.65-35,3-12.74,5.71-25.54,8.65-38.28.69-3,1.89-5.84,2.65-8.8a39.14,39.14,0,0,0,1.34-7.48,7.09,7.09,0,0,1,3.08-5.65c4.44-3.37,9.6-4.36,14.94-5A39.49,39.49,0,0,0,659,70.29c2.45-.73,4.61-1.25,6.88.67,2.91,2.47,4.67,5.31,3.35,9.07-.83,2.35-2.51,4.39-3.53,6.69-1.35,3-2.9,6.07-3.51,9.27-1,5.22-1.23,10.59-4.4,15.16a1.82,1.82,0,0,0-.38,1.11c.87,6.51-1.45,12.45-3.23,18.49-1.33,4.52-2.92,9-1.35,13.81a5.11,5.11,0,0,1,0,2.43c-1.12,6.1-2.31,12.19-3.48,18.28a11.55,11.55,0,0,0-.38,1.8c0,6.07-2,11.58-4.62,17a6.42,6.42,0,0,0-.48,3.28,28.32,28.32,0,0,1-2.27,14.09c-1.63,3.84-2.55,7.71-1.82,11.94.51,2.93-.72,5.35-2.17,8-2.6,4.78-4.29,10-6.32,15.14-1.05,2.63-1.83,5.39-3,8-1.58,3.4-3.68,6.57-5.15,10-3.76,8.8-12.56,13.55-21.23,9.84-10.33-4.42-19.4-10.69-24.87-21.31-5.61-10.92-8.38-22.44-9.79-34.5A324.71,324.71,0,0,1,565,158.61c.41-11,1.57-22,2.24-33.05.32-5.31.25-10.65.4-16,.18-6.14.4-12.27.61-18.4.15-4.2.62-8.47-2.87-11.85Z"
        />
        <path
          class="cls-1"
          d="M1011.91,46.19c7.92,1.59,15.93,1.4,22,6.53,2.9,2.45,6.47,5.53,6.13,10.43,0,.78.74,1.62,1.14,2.43,2,4,3.9,8,5.95,12,1.29,2.48,2.74,4.88,4.17,7.28.36.6,1.3,1,1.38,1.6.73,5.47,4.79,9.56,6,14.88a56.45,56.45,0,0,0,3.5,10.39c1.21,2.76,3.22,3,5.35.88,3.22-3.25,6.24-6.69,9.31-10.08a2.5,2.5,0,0,0,.63-1.38c.55-5.61,3.91-9.65,7.14-14,2.71-3.62,4.31-8.06,6.7-12,1.18-1.91,3.14-3.33,4.56-5.12,1.13-1.42,2.52-2.95,2.85-4.61A21.59,21.59,0,0,1,1109.55,50a2.15,2.15,0,0,1,.82-.37c8.51-.13,16.85.37,23.5,6.72,2.25,2.14,3.09,4.43.89,7.27a8.87,8.87,0,0,0-1.74,4.21c-1.22,10-6.31,18.45-11,27-3.62,6.61-7.88,12.88-11.86,19.29-.16.26-.34.52-.49.78q-8.87,15.26-17.72,30.51c-1.74,3-3.57,5.92-5.34,8.88a2.55,2.55,0,0,0-.5,1.09c-.24,7-3.18,13.39-4.6,20.11-.46,2.17.15,4.65.59,6.93,1.41,7.34-1.46,14.12-2.54,21.15-.77,5-1.17,10-1.75,15.07-.27,2.29-.65,4.57-.84,6.87-.32,3.93-.49,7.87-.81,11.8-.2,2.49-.57,5-.87,7.44a52.53,52.53,0,0,0-.7,6.34,42.3,42.3,0,0,1-6.72,23.86c-.33.52-.63,1.06-1,1.55-4.1,5.5-5.92,5.74-11.42,1.75-4.47-3.23-9.14-6.19-13.49-9.56-6.19-4.79-10.29-10.44-8.75-19.13,2.14-12.08,3.93-24.25,5.23-36.45,2-19.08,3.62-38.21,5.16-57.34a23.86,23.86,0,0,0-1.22-9.33c-4-11.89-8.51-23.63-12.59-35.5-3.35-9.75-6.73-19.51-9.45-29.44C1017.26,70.19,1014.87,58.7,1011.91,46.19Z"
        />
        <path
          class="cls-1"
          d="M409.32,48c2.81,4.11,7,5.64,11.06,7.61,5,2.43,9.65,5.69,14.44,8.61a5.79,5.79,0,0,1,1.14,1c4.39,4.65,6,14.72,2.82,20.26-3.76,6.54-6.27,13.25-5.51,21,.16,1.65-.56,3.41-1,5.08-1.57,5.81-3.2,11.61-4.81,17.41a1.88,1.88,0,0,0-.12.6c.87,11.58-4.25,22.25-5.08,33.55-.51,6.82,0,13.72-.57,20.54a46.1,46.1,0,0,1-2.84,12.46c-3.76,9.94-6.73,19.95-6,31.05a42.08,42.08,0,0,0,18.83.53c3.12-.56,6.29-.74,9.43-1.17,5.82-.79,10.51,1.42,14.88,5.08,2.79,2.34,6.05,4.11,9,6.25,6.19,4.45,9.43,10.82,11.36,18a2.94,2.94,0,0,1-1.09,2.33c-2.31,1.77-4.75,3.37-7.17,5a22,22,0,0,0-2.65,1.52c-5.27,4.86-11.89,5-18.48,5.83-5.33.66-10.53,2.39-15.78,3.68-1.38.34-2.73.84-4.12,1.14-6.18,1.34-12.36,2.73-18.57,3.89a10.75,10.75,0,0,1-4.76-.45c-3.77-1.08-7.47-2.4-11.2-3.64a1.89,1.89,0,0,1-.79-.39c-5.16-5.51-10.65-10.77-15.38-16.63-5.88-7.29-5.92-15.83-3.53-24.5,2.51-9.14,5.37-18.2,7.59-27.4,1.49-6.2,2.24-12.59,3.12-18.92.74-5.33,1.24-10.7,1.74-16.06a14.71,14.71,0,0,0-.12-4.87,77.54,77.54,0,0,1-.92-31.09c1.88-11.7,3-23.57,5.69-35.07A193,193,0,0,1,407.14,53C407.93,51.54,408.48,49.93,409.32,48Z"
        />
        <path
          class="cls-1"
          d="M512.23,282.68a10.82,10.82,0,0,1-2.88-.93,89.21,89.21,0,0,1-9.2-5.82,24.52,24.52,0,0,1-4.79-5.26,87.44,87.44,0,0,1-5.66-8.78,8.3,8.3,0,0,1-.68-4.11c.24-8.79.8-17.58.81-26.37,0-6.47-1.19-13-.92-19.42q1-24.18,2.9-48.33c.68-8.43,2.4-16.77,3.63-25.16a9.15,9.15,0,0,0,.06-2.45c-1.13-9.66.79-19.17,1.37-28.74a7.48,7.48,0,0,0,.12-.92c-.4-7.81.9-15.43,2.12-23.11.89-5.62.63-11.44.52-17.16-.05-2-1.11-4.1-1.86-6.09s-.22-3.35,1.9-2.89c5.68,1.24,10.93-.87,16.36-1.6.5-.07,1.05-.45,1.48-.33,5.63,1.63,11.8,2.4,16.69,5.32,3.5,2.09,6.61,6.79,7.49,10.85a20.1,20.1,0,0,1-2.86,15.32,5.51,5.51,0,0,0-.41,3.19c.15,2.17.76,4.33.73,6.49-.06,5.32-.32,10.64-.59,16-.14,2.75-.53,5.48-.69,8.23-.09,1.4.12,2.81.09,4.21a42.13,42.13,0,0,1-.31,5.15c-.44,3.08-1.56,6.15-1.47,9.2.17,6.12,1.51,12.24,1.32,18.32-.15,4.9-1.86,9.75-2.84,14.63a6.7,6.7,0,0,0-.3,3.09,15.92,15.92,0,0,1-.6,12.21,7.62,7.62,0,0,0-.68,3.87c.89,7.63-.58,15-2.32,22.33a15.29,15.29,0,0,0-.46,6.07c1.47,8,.38,15.9-.67,23.82a9.72,9.72,0,0,0-.08,3.94c3,9.58.22,19.33.87,29,.19,2.85-2.32,4.47-5.09,4.85C521.28,281.81,517.2,282.15,512.23,282.68Z"
        />
        <path
          class="cls-1"
          d="M508.05,1.31c3.62-.52,6.64-1.55,9.53-1.26a145,145,0,0,1,16.75,3c1,.22,1.71,1.86,2.39,3,.88,1.41,1.58,2.91,2.36,4.38.19.35.58.77.5,1.05-1,3.61-1.52,7.52-3.3,10.72s-5.33,4.16-9,4.46c-8.91.74-11.29-.56-14.71-8.91A77.58,77.58,0,0,1,510.41,10c-.59-2-1.28-4-1.84-6A26.2,26.2,0,0,1,508.05,1.31Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #fff;
}
</style>
