<template>
  <div class="home">
    <Hero />

    <section class="mx-8 md:mx-20">
      <div
        class="
          grid grid-cols-5
          items-center
          content-center
          gap-10
          md:gap-20
          mb-20
          lg:gap-20
          xl:gap-40
          lg:mx-20
          mt-5
        "
      >
        <img src="@/assets/brunch-icon.svg" />
        <img src="@/assets/lunch-icon.svg" />
        <img src="@/assets/sushi-icon.svg" />
        <img src="@/assets/bebidas-icon.svg" />
        <img src="@/assets/menu-icon.svg" />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card
          v-for="dish of menu"
          :key="dish.name"
          :img="require('@/assets/' + dish.img)"
          :name="dish.name"
          :ingredients="dish.ingredients"
        />
      </div>
    </section>

    <section class="promocional mt-28 flex justify-center items-center">
      <div class="w-1/2 lg:w-4/12 pt-20">
        <PromocionalText />
      </div>
    </section>

    <section
      class="
        sushi-menu
        flex flex-col
        py-20
        px-8
        md:px-20
        relative
        overflow-visible
      "
    >
      <div class="flex justify-center items-center">
        <div class="w-96 mb-10">
          <SushiTitulo />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-10">
        <Card
          v-for="dish of SushiMenu"
          :key="dish.name"
          :img="require('@/assets/' + dish.img)"
          :name="dish.name"
          :ingredients="dish.ingredients"
        />
      </div>
      <div class="flex justify-center mt-16">
        <button class="btn py-2 px-10">Ver Mas</button>
      </div>

      <img
        src="@/assets/leaves-illustration.svg"
        class="absolute bottom-0 -left-8 h-56"
      />
      <img
        src="@/assets/mancha-illustration.svg"
        class="absolute -right-6 -bottom-20 h-72"
      />
    </section>

    <section class="py-10 mx-8 md:mx-20">
      <div class="flex justify-center">
        <div class="w-96">
          <EspecialesTexto></EspecialesTexto>
        </div>
      </div>

      <div
        class="
          grid grid-cols-1
          items-center
          content-center
          md:grid-cols-2
          gap-20
          md:gap-10
          mt-20
        "
      >
        <div
          class="
            justify-start
            pl-8
            flex
            items-center
            especiales-bg
            w-full
            h-96
            bg-cover bg-center
          "
        >
          <div class="flex items-center flex-col">
            <img src="@/assets/happy-hour-texto.svg" class="w-30 mb-3" />
            <img src="@/assets/happy-hour-texto-2.svg" class="w-32" />
          </div>
        </div>

        <div class="flex flex-col justify-center items-center">
          <div class="grid grid-cols-3 gap-8 items-center content-center">
            <div
              v-for="item of SpecialIcons"
              :key="item.name"
              class="flex items-center flex-col"
            >
              <img :src="require('@/assets/' + item.icon)" class="h-16" />
              <p class="text-center mt-2 font-semibold">{{ item.name }}</p>
            </div>
          </div>
          <div class="flex justify-center mt-14">
            <button class="btn py-2 px-10">Ver Mas</button>
          </div>
        </div>
      </div>
    </section>

    <section class="py-10 mx-8 md:mx-20">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-3 mt-20">
        <div>
          <div class="brunch-bg w-full h-96"></div>
          <p class="uppercase font-bold text-center mt-4">Sabados y domingos</p>
          <p class="uppercase text-sm text-center mt-2">
            8:00<span class="text-xs lowercase">a.m</span> - 12:00<span
              class="text-xs lowercase"
              >p.m</span
            >
          </p>

          <div class="flex justify-center">
            <button class="btn mt-4">Ver Mas</button>
          </div>
        </div>
        <div>
          <div class="lunch-bg w-full h-96"></div>
          <p class="uppercase font-bold text-center mt-4">Lunes a Viernes</p>
          <p class="uppercase text-sm text-center mt-2">
            11:00<span class="text-xs lowercase">a.m</span> - 04:00<span
              class="text-xs lowercase"
              >p.m</span
            >
          </p>

          <div class="flex justify-center">
            <button class="btn mt-4">Ver Mas</button>
          </div>
        </div>
      </div>
    </section>

    <section class="relative mt-20">
      <div
        class="
          grid grid-cols-1
          sm:grid-cols-2
          z-10
          lg:ml-32 lg:mr-48
          pb-10
          md:pb-0
        "
      >
        <div
          class="
            md:mt-72
            w-4/6
            mx-auto
            flex
            items-center
            justify-center
            flex-col
          "
        >
          <div class="relative">
            <img
              src="@/assets/delivery-icon.svg"
              class="
                w-12
                md:w-14
                absolute
                bottom-16
                sm:bottom-16
                md:bottom-16
                -left-16
              "
            />

            <div>
              <HazTuPedido class="w-44 lg:w-60 mx-auto" />
              <DeliveryTexto class="lg:w-96 w-56 sm:w-60" />
              <div class="flex justify-center space-x-3 my-4 relative">
                <img src="@/assets/pedidos-ya.png" class="delivery-icon" />
                <img src="@/assets/pedidos-ya.png" class="delivery-icon" />
                <img src="@/assets/pedidos-ya.png" class="delivery-icon" />
                <img
                  src="@/assets/delivery-grafico.svg"
                  class="
                    w-12
                    absolute
                    bottom-2
                    -right-4
                    sm:-right-2
                    lg:right-16
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center">
          <img
            src="@/assets/delivery-phone.png"
            class="md:w-80 md:h-auto h-64 mt-4"
          />
        </div>
      </div>

      <div class="delivery-bg absolute bottom-0 left-0 w-full"></div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import Card from "@/components/Card.vue";
import PromocionalText from "@/components/PromocionalText.vue";
import HazTuPedido from "@/components/HazTuPedido.vue";
import SushiTitulo from "@/components/SushiTitulo.vue";
import EspecialesTexto from "@/components/EspecialesTexto.vue";
import DeliveryTexto from "@/components/DeliveryTexto.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Card,
    PromocionalText,
    SushiTitulo,
    DeliveryTexto,
    EspecialesTexto,
    HazTuPedido,
  },
  setup() {
    const menu = [
      {
        img: "pulpo-brasa.jpg",
        name: "Pulpo a la Brasa",
        ingredients: [
          "Pulpo a la brasa",
          "cama de rucula",
          "tomate cherry",
          "papines rostizados",
        ],
      },
      {
        img: "smoked-burger.jpg",
        name: "Smoked Burger",
        ingredients: [
          "Pan artesano",
          "Briache",
          "Carnes angus",
          "8 onz",
          "coleslaw",
          "costilla ahumada",
          "Salsa mayo",
          "papa fritas",
        ],
      },
      {
        img: "satay-mixto.jpg",
        name: "Satay mixto",
        ingredients: [
          "Pinchos mixto de pollo y res",
          "platano maduro tempura",
          "salsa teriyaki",
        ],
      },

      {
        img: "parrillada-mixta.jpg",
        name: "Parrillada mixta",
        ingredients: [
          "Parilla mixta de Picaña",
          "salchicha",
          "flat meat",
          "Pechuga de pollo",
        ],
      },
    ];

    const SushiMenu = [
      {
        img: "poke-bowl.jpg",
        name: "Poke Bowl",
        ingredients: [
          "Arroz de sushi",
          "maiz",
          "guizante",
          "aguacate",
          "pepino",
          "zanahoria",
          "salmon",
          "ajonjoli",
        ],
      },
      {
        img: "arcoiris-roll.jpg",
        name: "Arco Iris Roll",
        ingredients: [
          "Salmon",
          "cream cheese",
          "nori",
          "aguacate",
          "tuna",
          "ajonjoli",
          "puerros finos",
          "honey wasabi",
          "salsa miel",
        ],
      },
      {
        img: "arcoiris-roll.jpg",
        name: "Spring roll",
        ingredients: [
          "Hoja de Arroz",
          "Camarón",
          "Aguacate",
          "Pepino",
          "Zanahoria",
          "Wakame",
          "ajonjolí",
        ],
      },

      {
        img: "tuna-tartare.jpg",
        name: "Tuna Tartare",
        ingredients: [
          "Tuna",
          "Aguacata",
          "Puerro fino",
          "Ajonjolí",
          "Suquini",
          "Soya",
          "Rabáno",
          "Reduccion Mirin",
          "Salsa Miel",
        ],
      },

      {
        img: "tuna-tartare.jpg",
        name: "Quinoa Roll",
        ingredients: [
          "Camarón",
          "Quínoa",
          "Wakame",
          "Plátano Maduro",
          "Cream Cheese",
          "Nori",
          "Salsa Miel",
        ],
      },

      {
        img: "tuna-tartare.jpg",
        name: "Brisal Roll",
        ingredients: [
          "King Crab",
          "Camarón",
          "Fideos de Arroz",
          "Atún",
          "Aguacate",
          "Nori",
          "Salsa Miel",
        ],
      },
    ];

    const SpecialIcons = [
      {
        name: "Whisky",
        icon: "whisky-icon.svg",
      },
      {
        name: "Cosmopolitan",
        icon: "cosmopolitan-icon.svg",
      },
      {
        name: "Cocteles",
        icon: "cocteles-icon.svg",
      },
      {
        name: "Vino",
        icon: "vino-icon.svg",
      },
      {
        name: "Margaritas",
        icon: "margaritas-icon.svg",
      },
      {
        name: "Cervezas",
        icon: "cervezas-icon.svg",
      },
    ];

    return { menu, SushiMenu, SpecialIcons };
  },
};
</script>

<style>
.promocional {
  background: url("../assets/promocional-background.jpg");
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sushi-menu {
  background: url("../assets/sushi-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.btn {
  @apply px-7 py-1 font-bold rounded-lg bg-green-800 text-white uppercase;
}

.delivery-bg {
  background: url("../assets/delivery-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
  z-index: -10;
}

.especiales-bg {
  background: url("../assets/happy-hour-background.jpg");
}

@media (min-width: 650px) {
  .delivery-bg {
    height: 500px;
  }
}

body {
  overflow-x: hidden;
}

.brunch-bg {
  background: url("../assets/brunch.png");
  background-size: cover;
  background-position: center;
}

.lunch-bg {
  background: url("../assets/lunch.png");
  background-size: cover;
  background-position: center;
}

.delivery-icon {
  @apply w-10;
}
</style>
