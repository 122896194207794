<template>
  <div class="flex flex-col">
    <img :src="img" class="h-56 lg:h-56 object-cover" />
    <p class="uppercase font-bold text-center mt-2">{{ name }}</p>
    <p class="text-center text-xs mt-1 mb-2">{{ Ingredients }}</p>
    <div class="flex flex-grow mt-4" />
    <button
      class="
        rounded-full
        mx-auto
        px-5
        text-base
        py-1.5
        bg-green-700
        font-bold
        text-white
      "
    >
      {{ price }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: String,
      default: () => "PRECIO",
    },
    img: {
      type: String,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "",
    },
    ingredients: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    Ingredients() {
      return this.ingredients.join(" | ");
    },
  },
};
</script>

<style></style>
