<template>
  <section class="hero">
    <Header />
    <div
      class="grid grid-cols-1 xl:grid-cols-2 items-center content-center mt-28"
    >
      <div class="flex justify-center md:justify-end">
        <BrisalText
          class="md:w-10/12 lg:w-8/12 mx-auto max-w-md xl:h-96 xl:auto"
        />
      </div>
      <div class="flex justify-center mt-4">
        <HeroText class="xl:h-8 max-w-xl" />
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import BrisalText from "@/components/BrisalText.vue";
import HeroText from "@/components/HeroText.vue";

export default {
  name: "Home",
  components: {
    Header,
    BrisalText,
    HeroText,
  },
};
</script>

<style>
.hero {
  background: url("../assets/bienvenida-background.png");
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
