<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 908.33 69.48">
    <defs></defs>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_7" data-name="Capa 7">
        <text class="cls-1" transform="translate(0 51)">
          H
          <tspan class="cls-2" x="55.92" y="0">A</tspan>
          <tspan class="cls-3" x="115.32" y="0">Z</tspan>
          <tspan class="cls-4" x="186.6" y="0">T</tspan>
          <tspan x="236.76" y="0">US PE</tspan>
          <tspan class="cls-5" x="471.12" y="0">D</tspan>
          <tspan x="525.95" y="0">I</tspan>
          <tspan class="cls-6" x="553.31" y="0">D</tspan>
          <tspan class="cls-7" x="608.09" y="0">O</tspan>
          <tspan x="668.39" y="0">S POR</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  font-size: 60px;
  fill: #fff;
  font-family: NexaBold, Nexa Bold;
  letter-spacing: 0.2em;
}
.cls-2 {
  letter-spacing: 0.24em;
}
.cls-3 {
  letter-spacing: 0.2em;
}
.cls-4 {
  letter-spacing: 0.22em;
}
.cls-5 {
  letter-spacing: 0.21em;
}
.cls-6 {
  letter-spacing: 0.21em;
}
.cls-7 {
  letter-spacing: 0.22em;
}
</style>
