<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 941.71 201.37">
    <defs></defs>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_5" data-name="Capa 5">
        <path
          class="cls-1"
          d="M88,161.52c1,1.32,2.57,3.3,4,5.33a141.64,141.64,0,0,1,8.3,11.9,7.79,7.79,0,0,1,.44,6.19,50.39,50.39,0,0,1-6.33,9.94c-1.85,2.35-5,2.53-7.62,1.13s-5-2-7.18.56a2.22,2.22,0,0,1-1.43.54c-4.33.32-8.71,1.1-13,.73-4.68-.41-9,.77-13.51,1.21-3.12.31-6.32-.47-9.49-.57-5.22-.16-10.47-.39-15.17,2.6a1.55,1.55,0,0,1-1,.28c-5.19-.87-10.49-1.37-15.53-2.8-4-1.14-6.64-4.51-8.73-8.13-2.79-4.85-1.73-10-1-15a104.86,104.86,0,0,1,3.23-15,144.15,144.15,0,0,1,5.34-13.78,46.34,46.34,0,0,0,3.16-15.19c.42-6.68,1.59-13.3,2.37-20,1-8.59,1.5-17.28,3-25.78,2.17-11.88,5.13-23.61,7.72-35.42a23.51,23.51,0,0,0,.86-5.48C26.37,37.48,29,30.85,31.2,24.11c.74-2.24,1.1-4.61,1.77-6.87a3.91,3.91,0,0,1,1.32-2.13c4-2.55,7.83-5.57,12.18-7.26C50.3,6.36,54.8,6.73,58.86,5.72,64.42,4.34,70.2,4,75.41.83c2.24-1.36,6-.74,8.92-.3,2.27.34,4.37,1.8,6.56,2.74,2.72,1.17,5.5,2.2,8.16,3.5A8.71,8.71,0,0,1,104,13.59a7.22,7.22,0,0,1-3.37,7.06c-2,1.35-4.41,2-6.65,3s-4.59,1.52-6.49,2.84c-5.63,3.9-11.52,6.62-18.59,6a13.48,13.48,0,0,0-3.94.36c-4.5,1-5.37,2.13-6,6.73a43.05,43.05,0,0,1-1.55,7.21c-1.94,6-1.1,12.2-1.47,18.31-.11,1.84,1.14,2.08,2.72,1.79a56.54,56.54,0,0,1,8.87-1.38c4.07-.1,8.15.48,12.22.55,5.81.1,11.62-.07,17.43,0a6.76,6.76,0,0,1,3.73,1.15c5,3.78,9,8,9.15,15.07.13,5.19-2,9.38-3.63,13.91a1.6,1.6,0,0,1-.8,1c-4,1.16-7.88,2.5-11.91,3.29-3.59.71-7.31.81-11,1.19a1.48,1.48,0,0,0-.7.34c-5.71,4.26-12.23,4.34-18.93,3.87a28.88,28.88,0,0,0-6.85.61c-.61.1-1.33,1-1.55,1.71-1.12,3.53-2,7.12-3.15,10.65-.44,1.38-1.59,2.58-1.85,4-.38,2.1-.11,4.31-.4,6.43-.85,6.11-2.25,12.17-2.65,18.31s.05,12.51.27,18.77c.07,2.15,1.85,2.07,3.38,1.88,6.83-.85,13.66-1.75,20.49-2.65,3.15-.41,6.34-.7,9.43-1.37A73,73,0,0,0,88,161.52Z"
        />
        <path
          class="cls-1"
          d="M287,173.51c.89-15.52,2.21-31,2.54-46.56s3.43-30.68,5.22-46c1.32-11.34,1.57-22.7,2.42-34,.22-2.9,3.78-6.39,6.58-7.09,5.11-1.27,9.87,1.43,14.88.94,2.62-.25,5.26-.32,7.88-.6,3.06-.33,6.09-1.07,9.15-1.16,11.3-.33,22.63-.07,33.89-.83a83.79,83.79,0,0,1,21,1.26c5.59,1,8,5.28,9.88,9.74,1.42,3.42-3,7.35-6.63,7.07a49.39,49.39,0,0,0-10.12.74c-2.86.37-5.79,1.85-8.5,1.49-4.42-.59-8.29.37-12.5,1.33-6.53,1.49-13.35,1.78-20.06,2.37-4,.35-8.09.37-12.14.42-2.32,0-3.47,1.13-3.51,3.36,0,2,.17,4,.15,6.07,0,2.44-.21,4.88-.27,7.32-.08,3.29,1,4.71,4.27,4.51,8.8-.54,17.59-1.37,26.38-2.15,4.3-.37,8.59-1.2,12.88-1.2,2.13,0,4.35,1.2,6.35,2.21,5.13,2.58,9.53,5.93,12.25,11.26s.36,13.33-4.84,16.1a38.11,38.11,0,0,0-5.75,3.71A19.66,19.66,0,0,1,367.45,118c-4.11.37-8.17,1.28-12.27,1.83s-8.18,1-12.29,1.36c-3.86.32-7.75.36-11.62.62-3.34.22-4.65,1.76-4.94,5-.76,8.84-1.61,17.68-2.48,26.52-.21,2.14-.41,4.14,1.18,6a5.21,5.21,0,0,0,4.87,2c7.49-.83,14.88-1,22.32,1,3.94,1.07,8.23.83,12.36,1.26a5.63,5.63,0,0,1,2.59.84c2,1.41,3.78,3,5.7,4.49,2.71,2.07,5.5,4,8.18,6.14,1.31,1,2.34,2.44,3.69,3.4,1.67,1.17,3.35,2.38,2.93,4.6-.46,2.5-1.71,4.84-4.42,5.45a48.81,48.81,0,0,1-10.14,1.5c-8.3.08-16.61-.19-24.91-.43-4.65-.14-9.3-.84-13.92-.65-3.35.14-6.63,1.49-10,2a43.64,43.64,0,0,1-6.27.13c-1.73,0-3.57-.38-5.16.09-4.94,1.45-9.84.74-14.72.09-1.29-.17-2.63-1.37-3.56-2.43a45,45,0,0,1-5.78-7.57c-1.25-2.28-1.59-5.05-2.34-7.6Z"
        />
        <path
          class="cls-1"
          d="M760.24,173.5c.89-15.52,1.75-31,2.68-46.56.39-6.51.65-13,1.43-19.5,1.57-13,3.66-26,5.12-39.11.77-6.91.68-13.92.94-20.89.13-3.46,3.55-6.83,6.86-7.62,5.05-1.2,9.7,1.49,14.62,1,2.54-.27,5.1-.26,7.63-.53,3.23-.35,6.43-1.14,9.66-1.24,11.21-.34,22.46-.06,33.64-.83a83.28,83.28,0,0,1,21,1.27c5.58,1,8,5.27,9.88,9.75,1.4,3.42-3,7.38-6.66,7a29.36,29.36,0,0,0-9.35.55c-4.82,1.12-9.48,2.65-14.51,1.06-.92-.29-2.17.41-3.24.73a32.68,32.68,0,0,1-4.76,1.49c-7.56,1-15.13,1.9-22.71,2.65a66,66,0,0,1-8-.08c-3.28-.07-4.26.68-4.31,3.87,0,2.72.27,5.44.31,8.17,0,1.21-.28,2.42-.34,3.63-.23,4.91.65,5.81,4.74,5.56,8.89-.56,17.75-1.55,26.62-2.36,4-.37,8.09-1.09,12.11-1,2.22.06,4.5,1.31,6.58,2.35,5.06,2.53,9.33,5.9,12,11.14s.35,13.32-4.85,16.09a37.44,37.44,0,0,0-5.76,3.71A19.62,19.62,0,0,1,840.68,118c-4.12.38-8.18,1.29-12.28,1.83s-8.18,1-12.28,1.36c-3.87.32-7.75.36-11.62.62-3.33.22-4.65,1.77-4.93,5-.76,8.85-1.61,17.69-2.49,26.52-.21,2.15-.39,4.15,1.2,6a5.18,5.18,0,0,0,4.87,2c7.49-.82,14.89-.95,22.33,1.06,3.94,1.06,8.23.82,12.35,1.25a5.5,5.5,0,0,1,2.58.86c2,1.4,3.79,3,5.71,4.48,2.71,2.06,5.5,4,8.17,6.14,1.32,1,2.35,2.43,3.7,3.39,1.67,1.18,3.34,2.39,2.92,4.61-.47,2.5-1.72,4.84-4.44,5.44a48.71,48.71,0,0,1-10.14,1.5c-8.65.07-17.31-.19-26-.44-4.2-.12-8.42-.87-12.59-.66-3.44.18-6.8,1.51-10.24,2.05a42.36,42.36,0,0,1-6.28.11c-1.72,0-3.57-.37-5.16.1-4.93,1.45-9.83.72-14.71.09a6.26,6.26,0,0,1-3.41-2.23,49.28,49.28,0,0,1-6.09-8c-1.21-2.18-1.44-4.92-2.1-7.41Z"
        />
        <path
          class="cls-1"
          d="M600.09,161.51a48,48,0,0,1-2,6.07c-1.61,3.38-3.54,6.61-5.24,10a19.45,19.45,0,0,0-.68,2.73c-1.39,4.26-2.53,8.68-6.19,11.72-4,3.29-8.92,2.84-12.71-.61-5.08-4.63-5.8-10.16-4.09-16.39,2.23-8.08,4.33-16.19,6.72-24.22,1.08-3.62,2.77-7,4.11-10.59,6.74-17.89,13.22-35.89,20.31-53.66,2.82-7.07,7-13.58,10.46-20.43,1.82-3.62,4.51-7,3.26-11.59-.38-1.42,3.21-5.4,5-6,3.7-1.33,7.4-2.69,11.07-4.14,2.61-1,3.76-.55,4.44,2.2,2.52,10.07,5,20.14,7.49,30.22,1.78,7.35,3.4,14.74,5.21,22.08,1.07,4.33,2.5,8.57,3.55,12.91,2.92,12.16,5.89,24.31,8.53,36.53,2,9,3.58,18.12,5,27.23,1,6,.69,12-2.56,17.51a5.74,5.74,0,0,1-7.77,1.93,51.26,51.26,0,0,1-6.05-3.79,6.08,6.08,0,0,1-1.72-3.45c-.37-2-.17-4-.49-6-1.34-8.35-2.79-16.68-4.2-25,0-.26-.07-.53-.13-.79-1.14-5.69-2.5-6.33-7.69-3.85a35.76,35.76,0,0,1-3.77,1.14c-1.84.65-3.66,1.36-5.48,2.06-.81.31-1.59.73-2.42,1s-1.85.08-2.58.47c-4.36,2.37-8.56.93-12.81-.27a6.81,6.81,0,0,0-3.07-.22C599.46,157,599.46,157,600.09,161.51Zm25.24-71.92a42,42,0,0,0-3,3.92c-2.93,5-5.79,10-8.64,15.1a23.15,23.15,0,0,0-1.67,3.48c-.7,2-1.28,4-1.83,6-.2.7-.5,1.87-.19,2.12a3,3,0,0,0,2.38.51,20.33,20.33,0,0,0,5.54-2.45c3-2.14,5.85-3.56,9.59-2.26a7.08,7.08,0,0,0,2.89-.06c3.32-.35,4.06-1.66,2.92-4.77-2.26-6.18-4.45-12.39-6.68-18.58C626.37,91.91,626,91.21,625.33,89.59Z"
        />
        <path
          class="cls-1"
          d="M458.81,187.34a29.84,29.84,0,0,1-12.36-2.66c-4.7-1.91-9.69-3.07-14.54-4.59-7.24-2.25-12.73-7.14-18-12.22a72.84,72.84,0,0,1-7.34-8c-4.87-6.3-6.28-13.9-7.47-21.52a3.15,3.15,0,0,1,0-1.06c1.82-11.65,3.2-23.39,7.37-34.56,1.44-3.87,2.17-8,3.84-11.81,5.92-13.34,15.21-24,26.38-33.32a71.58,71.58,0,0,1,18.53-11.47c4.19-1.74,8.39-2.9,13.15-1.11,3.37,1.26,7.22,1.22,10.85,1.75.17,0,.42,0,.52.05,3.74,3.47,9.46,4.87,11,10.59.69,2.51-1.71,5.43-5.19,5.78-3.82.39-7.68.32-11.52.52a3.71,3.71,0,0,0-2.21.62c-3.2,3-6.27,6.05-9.43,9.06-1.9,1.8-3.92,3.47-5.8,5.29-2.69,2.62-5.45,5.2-7.91,8a35.64,35.64,0,0,0-5,7.14c-2.39,4.69-4.18,9.69-6.61,14.35-3.21,6.17-2.85,13-4.27,19.48s-.95,12.69-.32,19a20.5,20.5,0,0,0,6.33,12.49,46.56,46.56,0,0,0,8,6c4.33,2.69,8.06,2.11,11.74-1.4a13.62,13.62,0,0,1,1.35-1.28c6.38-4.8,10.11-11.22,12.55-18.78,1.76-5.47,6.19-8.67,11.88-9.79,2.67-.52,5.42-1.3,8.06-1.07a20.9,20.9,0,0,1,7.65,2.55c2.69,1.44,1.57,4.38,1.77,6.75.12,1.46.3,2.93.31,4.39a4.87,4.87,0,0,1-.42,2.7c-2.51,3.61-5.27,7-7.78,10.65a35.24,35.24,0,0,0-3.72,6.5c-2.3,5.56-4.11,11.15-10.82,13.42-4.58,1.56-8.65,4.58-13.15,6.44C464,187.19,461.32,187,458.81,187.34Z"
        />
        <path
          class="cls-1"
          d="M221.45,38.84c6.19,0,11.82.06,17.45,0,3.07,0,6.15-.29,9.22-.55a29.29,29.29,0,0,1,15.58,3.09c1.5.72,3.15,1.11,4.63,1.85a22.88,22.88,0,0,1,10.15,10.48,29.41,29.41,0,0,1,2.46,8.6c.33,2.13.64,4.57-1.85,6.13-.61.38-.61,1.72-.88,2.62a52.76,52.76,0,0,1-1.83,5.88c-1.86,4.28-3.83,8.52-5.95,12.67-.67,1.32-2,2.32-3,3.46-.17.18-.48.25-.66.43-4.64,4.77-9.08,9.75-14,14.25-3.69,3.39-7.5,7-13,7.31-.44,0-.88.1-1.32.14-4.36.44-4.93,1.17-5.43,5.51-.29,2.51-1.11,5-1.65,7.43-1,4.63-2,9.27-2.93,13.92-.51,2.53-.91,5.09-1.37,7.64a1.09,1.09,0,0,1,0,.26c-3.22,9.18-2.35,19.13-5.35,28.34a24.14,24.14,0,0,1-2,4.2c-1.86,3.35-3.47,6.84-6.6,9.36-2.19,1.77-5.32,2.36-7.55.74-4-2.93-7.54-6.3-9.83-10.77a4.87,4.87,0,0,1-.39-2.92c3-15.94,6.31-31.84,9.19-47.81,4.2-23.36,8.08-46.77,12.23-70.13.45-2.56,1.74-5,2.7-7.44C221.16,49.05,223.86,44.77,221.45,38.84Zm17.3,43.1c0,4.86,1.18,5.7,4.38,3.43A23.32,23.32,0,0,0,249.65,79c3.38-5.54,6.4-11.38,7.53-17.9a9.34,9.34,0,0,0-.51-4.55c-1.33-3.62-4.36-3.88-7.78-3.94-2.69,0-3.78,1-4.46,3.3-1.15,3.8-3.75,7.19-3.11,11.49.09.55-.37,1.17-.47,1.77C240.09,73.71,239.36,78.22,238.75,81.94Z"
        />
        <path
          class="cls-1"
          d="M705.93,33.52c5.4,3.21,10.8,6.25,16,9.6a13.5,13.5,0,0,1,4.09,4.5c2.62,4.47,2,8.89-.83,13.14-2.07,3.12-2.65,6.61-2.64,10.35,0,2.31-.86,4.62-1.3,6.94-1,5-1.9,10-2.81,15-.44,2.42-.59,4.92-1.24,7.29a63.45,63.45,0,0,0-2.4,20.69,32,32,0,0,1-2.53,14.46,43.65,43.65,0,0,0-3.69,19.7c4.56,1.41,9.18,1.11,13.82.29,1.48-.27,3-.43,4.46-.64A13.18,13.18,0,0,1,737.7,158c2.07,1.74,4.52,3,6.68,4.68a18.83,18.83,0,0,1,7.42,11.8c.49,2.7-2.47,3.71-4.33,5-.91.65-2.24.77-3.05,1.5-3.62,3.29-8.12,3.29-12.56,3.9-3.56.49-7,1.62-10.53,2.42-5.42,1.22-10.82,2.49-16.27,3.52-1.16.22-2.52-.45-3.75-.82-2.09-.62-4.15-1.33-6.21-2a2.74,2.74,0,0,1-1.13-.52c-3.2-3.29-6.54-6.46-9.51-9.94-4.05-4.76-5.08-10.39-3.52-16.4,1.74-6.64,3.93-13.18,5.38-19.88,1.34-6.15,1.88-12.47,2.91-18.7.8-4.87-.07-9.61-.4-14.44a125.88,125.88,0,0,1,.56-17.67C690.56,74.35,694.79,59,701,44.18,702.52,40.47,704.36,36.88,705.93,33.52Z"
        />
        <path
          class="cls-1"
          d="M147.9,196.13l-13.26-.76a3.21,3.21,0,0,1-1.29-.22c-7.17-3.68-12.91-8.6-14-17.16a46.57,46.57,0,0,1-.28-9,6,6,0,0,1,2-3.72c.47-.4,2.2.37,3.19.91,4.93,2.74,9.74,5.67,14.73,8.29,1.17.62,2.86.44,4.28.33,1.12-.08,2.18-.8,3.3-.91,4.58-.47,6.61-3.55,7.95-7.43a24.1,24.1,0,0,0,.2-16.31c-2.3-6.68-5.85-12.48-9.82-18.29-7.17-10.48-13.77-21.4-15.87-34.28-.89-5.46-1.52-11-2.2-16.46a6.51,6.51,0,0,1,.27-2.88c2.8-7.8,5.5-15.67,10.66-22.34a57.78,57.78,0,0,1,6.14-7.16c1.87-1.73,4-3.13,7.11-2.5,1.42.3,3.58-1,4.83-2.16,4.47-4.22,8.31-4.45,13.1-.79.35.27.66.72,1,.8,6.78,1.47,8.26,8.24,12.36,12.39a5.46,5.46,0,0,1,.91,2.69c.46,2.33.67,4.71,1.26,7s-.14,3.12-2.23,3.54c-5.15,1-10.51,1.57-15.37,3.42A27.28,27.28,0,0,0,151.21,88.5c-1.66,3.9-1.11,7.73,1,11.65,3.22,6,5.82,12.27,8.67,18.43a17.55,17.55,0,0,0,1,2.43c4.38,6.56,7.32,13.94,12.06,20.34a26,26,0,0,1,5.55,15.12c.11,5.26,1.32,10.49,1.64,15.76.15,2.47-.58,5-1.08,7.51a2.62,2.62,0,0,1-1.4,1.44c-4,1.93-6.56,5.26-9.25,8.57-.81,1-2.29,1.48-3.5,2.14-.45.25-1.12.22-1.46.55-4.38,4.22-9.92,3.56-15.27,3.69Z"
        />
        <path
          class="cls-1"
          d="M905,196.13l-13.35-.76a3.45,3.45,0,0,1-1.29-.26c-7.19-3.68-12.87-8.66-14-17.22a45.78,45.78,0,0,1-.26-9,5.78,5.78,0,0,1,2-3.66c.48-.4,2.18.45,3.18,1,4.85,2.7,9.58,5.6,14.5,8.16,1.25.65,3,.47,4.53.38,1.11-.06,2.18-.79,3.3-.91,4.17-.46,6.24-3.1,7.58-6.76,2-5.46,2.51-10.81.6-16.54-2.3-6.87-5.93-12.82-10-18.77-7.18-10.48-13.76-21.42-15.83-34.31-.88-5.46-1.52-11-2.19-16.46a6.48,6.48,0,0,1,.31-2.87c2.8-7.8,5.76-15.49,10.58-22.4,2.64-3.79,5.42-7.17,9.62-9.13.92-.43,2.2,0,3.21-.36,1.85-.59,4-1,5.35-2.26,4.48-4,8.06-4.4,12.92-.81.42.32.79.83,1.26.94,6.84,1.58,8.33,8.42,12.47,12.65a4.56,4.56,0,0,1,.71,2.23c.45,2.41.71,4.87,1.3,7.25.49,2-.12,3.08-2.11,3.4-3.63.6-7.31,1-10.89,1.82a28.82,28.82,0,0,0-16.18,10.37c-2.89,3.58-5.48,7.29-4.94,12.16a29.35,29.35,0,0,0,1.71,5.73c.11.31.52.54.61.85,1.87,7.1,7.12,12.64,9,19.77.65,2.47,2.52,4.62,3.84,6.92,2.26,3.93,4.22,8.08,6.87,11.72A38.21,38.21,0,0,1,936,151.88a76.94,76.94,0,0,1,1.52,10.42,81.25,81.25,0,0,1,.86,10.54c-.07,3.69-.18,7.27-4.43,9.62-3,1.66-5,5.09-7.59,7.61a12.62,12.62,0,0,1-3.16,1.83c-.54.27-1.32.22-1.71.6-3.73,3.64-8.47,3.54-13.16,3.63Z"
        />
        <path
          class="cls-1"
          d="M532.33,193.25a7.58,7.58,0,0,1-1.74-.61c-2.34-1.36-4.64-2.8-7-4.23a4.3,4.3,0,0,1-1.45-1.12c-1.88-2.92-3.72-5.86-5.47-8.85a4.09,4.09,0,0,1-.27-2.05c.19-5.83.58-11.65.59-17.47,0-4.73-.81-9.47-.62-14.18.68-16.74.67-33.56,4.48-50a11.31,11.31,0,0,0,.1-2.11c.37-9.59.61-19.2,1.15-28.79.31-5.61,1.2-11.19,1.56-16.8a14.09,14.09,0,0,0-1-5.38c-.65-1.87-.41-2.57,1.72-2.57a44.36,44.36,0,0,0,7.29-.6c5.51-1,10.59.5,15.51,2.64.93.4,1.45,1.77,2.09,2.74.34.5.46,1.15.79,1.65.88,1.34,2.63,2.68,2.57,3.95a33.6,33.6,0,0,1-1.87,9.22c-.44,1.29-1.11,2.21-.23,3.52a3.08,3.08,0,0,1,.39,1.78c-.36,6.25-.89,12.5-1.12,18.75-.3,8.1-1.93,16.11-.37,24.33.61,3.22-1.05,6.86-1.62,10.32a28.65,28.65,0,0,0-.19,3c-.15,2.49-.25,5-.46,7.48-.09,1-.66,2.08-.55,3.08.54,5.07-.46,9.94-1.5,14.85a33.38,33.38,0,0,0-.22,5.76q-.31,7.52-.66,15a6.13,6.13,0,0,0-.13,1.83c2,6.25.35,12.65.79,19,.24,3.35-1.15,4.64-4.43,5Z"
        />
        <path
          class="cls-1"
          d="M529.39.67c4.79,0,9-.05,13.12,0a9.19,9.19,0,0,1,3.6.77,6.17,6.17,0,0,1,2.81,2.18c1.65,3,2.67,6.15.84,9.48a10.79,10.79,0,0,1-1.93,3,9.36,9.36,0,0,1-14.7-3c-1-2.2-1.33-4.7-2-7C530.59,4.31,530,2.56,529.39.67Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #0b565a;
}
</style>
