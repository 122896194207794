<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1197.05 70.32">
    <defs></defs>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <text class="cls-1" transform="translate(0 51)">
          C
          <tspan class="cls-2" x="66.24" y="0">O</tspan>
          <tspan class="cls-3" x="138.48" y="0">C</tspan>
          <tspan class="cls-2" x="204.78" y="0">INA INTERN</tspan>
          <tspan class="cls-4" x="785.85" y="0">A</tspan>
          <tspan class="cls-3" x="830.63" y="0">C</tspan>
          <tspan class="cls-5" x="896.93" y="0">I</tspan>
          <tspan class="cls-2" x="934.67" y="0">ON</tspan>
          <tspan class="cls-6" x="1073.15" y="0">A</tspan>
          <tspan class="cls-2" x="1140.05" y="0">L</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  font-size: 60px;
  fill: #fff;
  font-family: "Montserrat";
  letter-spacing: 0.43em;
}
.cls-2 {
  letter-spacing: 0.4em;
}
.cls-3 {
  letter-spacing: 0.43em;
}
.cls-4 {
  letter-spacing: 0.37em;
}
.cls-5 {
  letter-spacing: 0.42em;
}
.cls-6 {
  letter-spacing: 0.42em;
}
</style>
