<template>
  <header class="flex justify-between py-10 px-16">
    <img src="@/assets/Logo.svg" class="w-16" />

    <div class="flex items-center space-x-8">
      <nav class="space-x-8 hidden md:flex">
        <router-link
          v-for="route of routes"
          :key="route.name"
          class="nav-link tracking-widest uppercase"
          :to="route.url"
          >{{ route.name }}</router-link
        >
      </nav>

      <img src="@/assets/carrito-icon.svg" class="w-8 cursor-pointer" />

      <img
        src="https://img.icons8.com/material-outlined/24/ffffff/menu--v3.png"
        class="md:hidden block cursor-pointer"
        @click="isMobileNavActive = true"
      />
    </div>

    <div :class="['navMobile', { active: isMobileNavActive }]">
      <img
        src="@/assets/close-button.svg"
        class="fixed top-8 right-4 w-10"
        @click="isMobileNavActive = false"
      />
      <div class="flex justify-center items-center h-screen flex-col space-y-4">
        <router-link
          v-for="route of routes"
          :key="route.name"
          class="text-black font-semibold text-3xl"
          :to="route.url"
          >{{ route.name }}</router-link
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          url: "/",
          name: "Home",
        },
        {
          url: "/about",
          name: "About",
        },
        {
          url: "/menu",
          name: "Menu",
        },
        {
          url: "/blog",
          name: "Blog",
        },
        {
          url: "/contacto",
          name: "Contacto",
        },
        {
          url: "/delivery",
          name: "Delivery",
        },
      ],
      isMobileNavActive: false,
    };
  },
};
</script>

<style>
.nav-link {
  @apply text-white font-medium;
}

.navMobile {
  position: fixed;
  background: white;
  height: 100vh;
  width: 100vw;
  display: none;
  top: 0;
  left: 0;
}

.navMobile.active {
  display: block;
}
</style>
